import React, { useState, useEffect } from 'react';
import get from 'lodash.get';

import QuizStart from './QuizStart';
import QuizName from './QuizName';
import QuizStep from './QuizStep';
import QuizEnd from './QuizEnd';

import { getProductUrl } from 'helpers/url';
import isEqual from 'lodash.isequal';

const DEFAULT_RESULT = {
  name: '',
  email: '',
  checked: {},
};

export const sendEmail = async (email, resultBlocks) => {
  try {
    const response = await fetch('/.netlify/functions/sendEmail', {
      method: 'POST',
      body: JSON.stringify({
        toEmail: email,
        body: resultBlocks,
      }),
    });

    if (!response.ok) {
      //not 200 response
      return;
    }
  } catch (e) {
    //error
    console.log(e);
  }
};

function QuizEndBlock(block, allProducts) {
  let { name, products } = block;

  return {
    title: name,
    items: products.map(product => {
      let shopifyProduct = allProducts.find(
        p => p.handle === product.shopifyProduct.handle
      );
      return {
        img: get(shopifyProduct, 'images.0.localFile.publicURL'),
        title: get(shopifyProduct, 'title'),
        text: get(product, 'description'),
        btnLinkText: 'Shop',
        btnLinkHref: getProductUrl(product.shopifyProduct.handle),
      };
    }),
  };
}

function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default function Quiz({ page, allProducts, setHideFooter }) {
  let {
    title,
    description,
    backgroundImage,
    questions,
    results,
    resultImage,
  } = page;

  let [started, setStarted] = useState(false);
  let [nameStep, setNameStep] = useState(true);
  let [finished, setFinished] = useState(false);
  let [emailed, setEmailed] = useState(false);

  let [result, setResult] = useState(DEFAULT_RESULT);
  let products = [];

  useEffect(() => setHideFooter(finished));
  useEffect(() => {
    if (finished && !emailed) {
      if (validateEmail(result.email)) {
        sendEmail(result.email, products);
      } else {
        console.log('email address doesnt look valid');
      }
      setEmailed(true);
    }
  }, [finished, emailed, result.email, products]);

  function restartQuiz() {
    setStarted(true);
    setNameStep(false);
    setFinished(false);
    setEmailed(false);
    setResult({ ...DEFAULT_RESULT, name: result.name, email: result.email });
  }

  if (finished) {
    let currentAnswers = Object.values(result.checked).flat();

    let CMSResult = results.find(CMSResult => {
      let CMSAnswers = CMSResult.answers.flatMap(CMSAnswer => CMSAnswer.id);
      return isEqual(currentAnswers, CMSAnswers);
    });

    if (CMSResult && CMSResult.block && CMSResult.block.length > 0) {
      products = CMSResult.block.map(block => QuizEndBlock(block, allProducts));
    }
  }

  return (
    <>
      {!started && (
        <QuizStart
          title={title}
          description={description}
          image={backgroundImage}
          next={() => setStarted(true)}
        />
      )}
      {started && !finished && (
        <>
          {nameStep && (
            <QuizName
              result={result}
              setResult={setResult}
              prev={() => setStarted(false)}
              next={() => setNameStep(false)}
            />
          )}
          {!nameStep && (
            <QuizStep
              questions={questions}
              result={result}
              setResult={setResult}
              prev={() => setNameStep(true)}
              next={() => setFinished(true)}
            />
          )}
        </>
      )}
      {finished && (
        <QuizEnd
          result={result}
          products={products}
          image={get(resultImage, 'localFile.publicURL')}
          restartQuiz={restartQuiz}
        />
      )}
    </>
  );
}
