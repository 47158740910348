import React from 'react';
import get from 'lodash.get';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { isMobile } from 'react-device-detect';

import {
  Spacing,
  ImageWithDescHiddenOnMobile,
} from '@matchbox-mobile/kenra-storybook';

export default function QuizStart({ title, description, image, next }) {
  let isSmallScreen = false;
  if (isMobile) {
    isSmallScreen = true;
  }
  return (
    <Spacing removeSpaceTop removeSpaceBottom>
      <ImageWithDescHiddenOnMobile
        stylePage
        removeMargin
        bg={isSmallScreen ? null : get(image, 'localFile.publicURL')}
        title={title}
        text={documentToReactComponents(description.json)}
        btnText="TAKE QUIZ"
        onClick={next}
      />
    </Spacing>
  );
}
