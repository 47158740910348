import React, { useState } from 'react';

import { Spacing, Container, Quiz } from '@matchbox-mobile/kenra-storybook';
import get from 'lodash.get';

export default function QuizStep({ questions, result, setResult, prev, next }) {
  let [step, setStep] = useState(0);
  let question = questions[step];
  let { answers, multiple } = question;
  let { checked } = result;
  let currentStepChecked = get(result, `checked.${step}`, []);

  function setAnswer(answerId, newValue) {
    if (newValue && !multiple) currentStepChecked = [answerId];
    if (newValue && multiple) currentStepChecked.push(answerId);
    if (!newValue && !multiple) currentStepChecked = [];
    if (!newValue && multiple)
      currentStepChecked = currentStepChecked.filter(id => id !== answerId);

    setResult({
      ...result,
      checked: {
        ...checked,
        [step]: currentStepChecked,
      },
    });
  }

  return (
    <Spacing>
      <Container useMediumWidth>
        <Quiz
          step={`${step + 1}/${questions.length}`}
          title={question.text}
          checkboxes={answers.map(answer => ({
            label: answer.text,
            value: answer.id,
            id: answer.id,
            checked: currentStepChecked.includes(answer.id),
            onChange: e => setAnswer(answer.id, e.target.checked),
          }))}
          showNextBtn={currentStepChecked.length > 0}
          onClickNextBtn={() => {
            if (step < questions.length - 1) return setStep(step + 1);
            next();
          }}
          showBackBtn
          onClickBackBtn={() => {
            if (step > 0) return setStep(step - 1);
            prev();
          }}
        />
      </Container>
    </Spacing>
  );
}
