import React from 'react';

import { Spacing, Container, Quiz } from '@matchbox-mobile/kenra-storybook';

export default function QuizStep({ result, setResult, next }) {
  return (
    <Spacing>
      <Container useMediumWidth>
        <Quiz
          h1="LET’S BEGIN, WE WANT TO GET TO KNOW YOU BETTER!"
          inputs={[
            {
              value: result.name,
              placeholder: 'ENTER FIRST / LAST NAME',
              onChange: e => setResult({ ...result, name: e.target.value }),
            },
            {
              value: result.email,
              placeholder: 'Enter email',
              onChange: e => setResult({ ...result, email: e.target.value }),
            },
          ]}
          showNextBtn
          onClickNextBtn={next}
        />
      </Container>
    </Spacing>
  );
}
