import React, { useState } from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import Quiz from 'components/Quiz';

export default function QuizPage(props) {
  let [hideFooter, setHideFooter] = useState(false);

  let {
    data: { contentfulQuiz, allShopifyProduct },
  } = props;

  return (
    <Layout hideFooter={hideFooter}>
      <Quiz
        page={contentfulQuiz}
        allProducts={allShopifyProduct.nodes}
        setHideFooter={setHideFooter}
      />
    </Layout>
  );
}

export const query = graphql`
  query {
    contentfulQuiz {
      ...ContentfulQuizFields
    }
    allShopifyProduct {
      nodes {
        ...ShopifyProductFields
      }
    }
  }
`;
