import React from 'react';

import { Spacing, QuizResult } from '@matchbox-mobile/kenra-storybook';

export default function QuizEnd(props) {
  let { result, products, image, restartQuiz } = props;

  return (
    <Spacing removeSpaceTop removeSpaceBottom>
      <QuizResult
        mainImage={image}
        title={`Hi ${result.name}! Here are your customized product matches to care and style your healthiest hair.`}
        subtitle="Add any or all of the products below to achieve your hair goals."
        showRetakeBtn
        onClickRetakeBtn={e => {
          e.preventDefault();
          restartQuiz();
        }}
        products={products}
      />
    </Spacing>
  );
}
